import React, { useMemo, useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, StepLabel, StepContent, Box, IconButton, Icon } from '@mui/material'
import { NumberField, TextComponent, DateTimeField, MenuSelectorField, SingleOptionSelectorField, SingleOptionSelectorComponent, OptionSelectorComponent, ModelAutocompleteField } from '@front/squirtle'

import moment from 'moment'

const Row1 = ({
  MAX_BOOKING_VEHICLES,
  load_type,
  isB2B,
  autoAdjustPeriod,
  vehicle_count,
  handleDateTimeChange,
  handleVehicleCountChange,
  handleDateShortcuts,
  handleTimeShortcuts,
  handleScheduleTypeChange,
  isScheduled,
  schedule_id,
  ...props
}) => {
  const displayVehicleCount = useMemo(() => !!isB2B && load_type !== 'package', [isB2B, load_type])
  const getCurrentTime = useCallback(() => moment(), [])
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={5} >
        <DateTimeField
          name={'requestedAt'}
          dateProps={{
            label: I18n.t('date.label', { count: 1 }),
            onKeyDown: handleDateShortcuts,
            autoAdjustPeriod,
            enableAutoAdjust: true,
            inputFormat: 'DD/MM/YYYY'
          }}
          timeProps={{
            label: I18n.t('hour.label', { count: 1 }),
            onKeyDown: handleTimeShortcuts
          }}
          disablePast
          required
          defaultDateTime={getCurrentTime}
          afterChange={handleDateTimeChange}
        />
      </Grid>
      {!!isScheduled &&
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row' }}>
          <ModelAutocompleteField
            name={'order_schedule.schedule'}
            label={I18n.t('order.recurrence')}
            model_name='schedule'
            labelKeys={['name']}
            searchMode='search'
            searchQuery={'startsWith'}
            autocompleteKey={'name'}
            config={{ forced_filter: { type: { name: 'booking' } } }}
            selectFirst={false}
            loadOnMount
            required
          />
          <IconButton onClick={() => window.open(!!schedule_id ? `/schedule/${schedule_id}` : '/schedule/create?type=booking', '_blank')} color='primary'>
            <Icon>{!!schedule_id ? 'edit' : 'add_circle'}</Icon>
          </IconButton>
        </Grid>
      }
      <Grid item xs={3}>
        <MenuSelectorField
          name={'schedule_type'}
          label={I18n.t('order.type.other')}
          options={[
            { label: I18n.t('order.immediate.label', { count: 1 }), value: 'immediate' },
            { label: I18n.t('order.planned.label', { count: 1 }), value: 'planned' }
          ]}
          selectFirst
          required
          disabled
          afterChange={handleScheduleTypeChange}
        />
      </Grid>
      <Grid item xs hidden={!displayVehicleCount}>
        <NumberField
          name={'vehicle_count'}
          label={I18n.t('vehicle.number.short')}
          type={'number'}
          afterUserChange={handleVehicleCountChange}
          minValue={1}
          maxValue={MAX_BOOKING_VEHICLES}
        />
      </Grid>
      <Grid item xs>
        <NumberField
          name={'load_count'}
          label={I18n.t(`load.${load_type}.count`)}
          type={'number'}
          minValue={1}
          maxValue={8}
          required
          disabled={vehicle_count > 1}
        />
      </Grid>
      <Grid item xs hidden={load_type !== 'user'}>
        <SingleOptionSelectorField
          name={'has_luggage'}
          label={_.upperFirst(I18n.t('load.user.has_luggage'))}
          clearOnEmpty={false}
          disabled={vehicle_count > 1}
        />
      </Grid>
    </Grid>
  )
}

const Row2 = ({ handleDateShortcuts, handleTimeShortcuts, autoAdjustPeriod, nights, formatted_duration }) => {
  const getCurrentTime = useCallback(() => moment(), [])
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={4} >
        <DateTimeField
          name={'returnAt'}
          dateProps={{
            label: I18n.t('date.return.label', { count: 1 }),
            onKeyDown: handleDateShortcuts,
            autoAdjustPeriod,
            enableAutoAdjust: true
          }}
          timeProps={{
            label: I18n.t('hour.return.label', { count: 1 }),
            onKeyDown: handleTimeShortcuts
          }}
          disablePast
          defaultDateTime={getCurrentTime}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs>
        <TextComponent label={I18n.t('night.label', { count: nights || 0 })} value={nights} disabled />
      </Grid>
      <Grid item xs>
        <TextComponent label={I18n.t('ridemode.duration_time')} value={formatted_duration} disabled />
      </Grid>
    </Grid>
  )
}

const DateTimeSection = ({ stay_required, handleRoundTripChange, displayRoundTrip, isScheduled, handleAfterIsScheduledChange, ...rest }) => <>
  <StepLabel>
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant='h5'>{I18n.t('order.label.alter')}</Typography>
      <Box display={'flex'} alignItems={'flex-end'} sx={{ ml: 4 }} >
        <Box sx={{ mb: '6px' }}>
          <Typography color={!isScheduled ? 'primary' : 'text.label'} sx={{ fontSize: 18 }}>
            {I18n.t('order.punctual')}
          </Typography>
        </Box>
        <SingleOptionSelectorComponent
          value={isScheduled}
          optionLabel={I18n.t('order.recurring')}
          disableTypography
          getOptionLabelProps={() => (
            { sx: { color: !!isScheduled ? 'primary.main' : 'text.label', fontSize: 18 }, labelPlacement: 'end' }
          )}
          onChange={handleAfterIsScheduledChange}
        />
      </Box>
      <Box sx={{ ml: 1, mt: '2px', display: !!stay_required ? 'flex' : 'none', alignItems: 'center' }}>
        <Typography mt={'2px'} color={!displayRoundTrip ? 'secondary.main' : 'text'}>{I18n.t('come.simple')}</Typography>
        <OptionSelectorComponent
          sx={{ display: 'flex', alignItems: 'flex-end', pt: 1 }}
          value={!!displayRoundTrip ? 'return' : null}
          onChange={handleRoundTripChange}
          formGroupProps={{ sx: { ml: 1, p: 1 } }}
          options={[{ label: I18n.t('come.back'), value: 'return' }]} valueType='switch'
          getOptionLabelProps={() => ({
            sx: {
              color: !!displayRoundTrip ? 'secondary.main' : 'text'
            }
          })}
          getOptionProps={() => ({
            sx: {
              "&.MuiSwitch-root .MuiSwitch-switchBase": {
                color: "secondary.main"
              },
              "&.MuiSwitch-root .MuiSwitch-track": {
                backgroundColor: 'secondary.main'
              }
            }
          })}
        />
      </Box>
    </Box>
  </StepLabel>
  <StepContent>
    <Grid container spacing={1} rowSpacing={1.5}>
      <Row1 {...rest} isScheduled={isScheduled} />
      {!!stay_required && !!displayRoundTrip && <Row2  {...rest} />}
    </Grid>
  </StepContent>
</>

export default React.memo(DateTimeSection)
