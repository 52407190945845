import React, { useCallback, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import { TimeUtils } from '@front/volcanion/utils'

const withContainer = Component => props => {
  const MAX_BOOKING_VEHICLES = hooks.useRelayConstant('MAX_BOOKING_VEHICLES')
  const [deferTo, setDefertTo] = hooks.useFormState('deferTo')
  const [isScheduled, setIsScheduled] = hooks.useFormState('isScheduled')
  const setFormValues = hooks.useFormChange()
  const [threshold_RA] = hooks.useSetting('threshold_RA')
  const [
    load_type,
    vehicle_count,
    requestedAt,
    source,
    commercial_package,
    schedule_type,
    driver_opts,
    vehicle_opts,
    companyuser_id,
    companyservice_id,
    ridemode,
    schedule_id
  ] = hooks.useFieldValues([
    'load_type',
    'vehicle_count',
    'requestedAt',
    'source',
    'commercial_package',
    'schedule_type',
    'driver_opts',
    'vehicle_opts',
    'user.companyuser',
    'user.companyservice',
    'ridemode',
    'order_schedule.schedule'
  ])
  const [limit_RA] = hooks.useSetting('limit_RA')
  const [{ isB2B, displayRoundTrip, ridemode_info }, setFormState] = hooks.useFormState()
  const { nights, duration } = ridemode_info || {}
  const [companyuser] = hooks.useModel('companyuser', [companyuser_id], { populate: ['user.customerinfo', 'user.info', 'user.auth', 'contact'], single: true })
  const [companyuser_info] = hooks.useCompanyUserInfo(companyuser?.user?.user_id, companyservice_id)
  const [mode] = hooks.useModel('ridemode', [ridemode], { single: true })

  const stay_required = mode?.stay_required

  const [calculateDeferTo] = hooks.useModelFunction('order', 'calculateDeferTo')
  const handleVehicleCountChange = useCallback(Callbacks.handleVehicleCountChangeHandler(setFormValues, companyuser_info), [setFormValues, companyuser_info])
  const handleDateShortcuts = useCallback(Callbacks.handleDateShortcutsHandler(setFormValues, requestedAt), [setFormValues, requestedAt])
  const handleTimeShortcuts = useCallback(Callbacks.handleTimeShortcutsHandler(setFormValues, requestedAt, deferTo), [setFormValues, requestedAt, deferTo])
  const handleDateTimeChange = useCallback(Callbacks.handleDateTimeChangeHandler(setFormValues, threshold_RA), [setFormValues, threshold_RA])
  const handleGetDefertTo = useCallback(Callbacks.handleGetDefertToHandler(calculateDeferTo, setDefertTo), [calculateDeferTo, setDefertTo])
  const handleScheduleTypeChange = useCallback(Callbacks.handleScheduleTypeChangeHandler(setFormValues), [setFormValues])
  const handleRoundTripChange = useCallback(Callbacks.handleRoundTripChangeHandler(setFormValues, setFormState, displayRoundTrip), [setFormValues, setFormState, displayRoundTrip])
  const handleAfterIsScheduledChange = useCallback(Callbacks.handleIsScheduledChangeHandler(setIsScheduled, setFormValues), [setIsScheduled, setFormValues])

  const hasSource = !!source
  const deferToWatcher = [hasSource, commercial_package, schedule_type]

  useEffect(() => {
    if (!requestedAt || _.isEmpty(_.get(source, 'details')) || schedule_type !== 'planned') return
    handleGetDefertTo(requestedAt, source, _.compact(driver_opts), _.compact(vehicle_opts))
  }, deferToWatcher)

  const formatted_duration = !!duration ? TimeUtils.secondsToTime(duration, null, { hide_seconds: true }) : undefined
  const mergedProps = {
    formatted_duration,
    nights,
    displayRoundTrip,
    stay_required,
    MAX_BOOKING_VEHICLES,
    vehicle_count,
    isB2B,
    load_type,
    autoAdjustPeriod: limit_RA,
    handleVehicleCountChange,
    handleScheduleTypeChange,
    handleRoundTripChange,
    handleDateShortcuts,
    handleTimeShortcuts,
    handleDateTimeChange,
    handleAfterIsScheduledChange,
    isScheduled,
    schedule_id
  }


  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
